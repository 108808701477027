<template>
  <div>
    <div v-if="totem.ativo">
      <div v-if="loaded">
        <div v-if="totem.length">
          <div
            class="modal fade"
            id="infoPessoa"
            tabindex="-1"
            role="dialog"
            aria-labelledby="infoPessoaTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-centered modal-xl"
              role="document"
            >
              <div class="modal-content">
                <div
                  class="modal-header"
                  :style="
                    'background: ' +
                    (totem.background_header
                      ? totem.background_header
                      : '#15488a') +
                    ';' +
                    'color: ' +
                    (totem.color_header ? totem.color_header : '#ffffff') +
                    ';'
                  "
                >
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12 centered">
                      <span
                        style="
                          font-size: 30px;
                          font-weight: bold;
                          text-transform: uppercase;
                        "
                        >Olá <span style="color: red">{{ document.nome }}</span
                        >, seja bem-vindo!</span
                      >
                    </div>
                    <div
                      class="col-md-12 centered"
                      style="
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: bold;
                      "
                      v-if="!alterar"
                    >
                      Seu endereço atual é?
                    </div>
                  </div>
                  <div class="row" v-show="!alterar">
                    <div class="col-12">
                      <label><b>Nome:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="document.nome"
                        disabled
                      />
                    </div>
                    <div class="col-6">
                      <label><b>Nome da mãe:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="document.nomeMae"
                        disabled
                      />
                    </div>
                    <div class="col-6">
                      <label><b>Nome do pai:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="document.nomePai"
                        disabled
                      />
                    </div>
                    <div class="col-12">
                      <br />
                      <label><b>Endereço</b></label>
                    </div>
                    <div class="col-3">
                      <label><b>CEP:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.cep"
                        disabled
                      />
                    </div>
                    <div class="col-4">
                      <label><b>Tipo de endereço:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.tipo"
                        disabled
                      />
                    </div>
                    <div class="col-5">
                      <label><b>Logradouro:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.logradouro"
                        disabled
                      />
                    </div>
                    <div class="col-2">
                      <label><b>Número:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.numero"
                        disabled
                      />
                    </div>
                    <div class="col-3">
                      <label><b>Complemento:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.complemento"
                        disabled
                      />
                    </div>
                    <div class="col-4">
                      <label><b>Bairro:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.bairro"
                        disabled
                      />
                    </div>
                    <div class="col-3">
                      <label><b>Município:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.municipio"
                        disabled
                      />
                    </div>
                    <div class="col-2">
                      <label><b>UF:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.uf"
                        disabled
                      />
                    </div>
                    <div class="col-3">
                      <label><b>Telefone/Celular:</b></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mock.telefone"
                        v-mask="'(99) 9 9999-9999'"
                        disabled
                      />
                    </div>
                  </div>
                  <div v-show="alterar">
                    <div
                      class="row d-flex justify-content-center"
                      align="center"
                    >
                      <div class="col-6 mt-2">
                        <label style="font-size: 20px"
                          ><b>Informe seu celular com WhatsApp:</b></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="mock.telefone"
                          v-mask="'(99) 9 9999-9999'"
                        />
                        <div>
                          <h1>&nbsp;</h1>
                        </div>
                        <div
                          class="col-12 d-flex justify-content-center align-itens-center"
                        >
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 1"
                          >
                            <b style="font-size: 4.5vh">1</b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 2"
                          >
                            <b style="font-size: 4.5vh">2</b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 3"
                          >
                            <b style="font-size: 4.5vh">3</b>
                          </button>
                        </div>
                        <div
                          class="col-12 d-flex justify-content-center align-itens-center"
                        >
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 4"
                          >
                            <b style="font-size: 4.5vh">4</b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 5"
                          >
                            <b style="font-size: 4.5vh">5</b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 6"
                          >
                            <b style="font-size: 4.5vh">6</b>
                          </button>
                        </div>
                        <div
                          class="col-12 d-flex justify-content-center align-itens-center"
                        >
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 7"
                          >
                            <b style="font-size: 4.5vh">7</b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 8"
                          >
                            <b style="font-size: 4.5vh">8</b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 9"
                          >
                            <b style="font-size: 4.5vh">9</b>
                          </button>
                        </div>
                        <div
                          class="col-12 d-flex justify-content-center align-itens-center"
                        >
                          <button
                            class="btn btn-danger mr-1 mb-1 py-2 px-1"
                            @click="mock.telefone = mock.telefone.slice(0, -1)"
                          >
                            <b style="font-size: 4vh"
                              ><i class="fas fa-eraser"></i
                            ></b>
                          </button>
                          <button
                            class="btn btn-secondary mr-1 mb-1 p-2"
                            @click="mock.telefone += 0"
                          >
                            <b style="font-size: 4.5vh">0</b>
                          </button>
                          <button
                            class="btn btn-success mr-1 mb-1 py-2 px-1"
                            @click="sendMessage()"
                            data-dismiss="modal"
                          >
                            <b style="font-size: 4vh"
                              ><i class="fas fa-check"></i
                            ></b>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="centered">
                  <button
                    type="button"
                    class="btn btn-secondary mr-3 mb-3 mt-2"
                    data-dismiss="modal"
                    @click="getAgenda()"
                    v-if="!alterar"
                  >
                    Sim, este é meu endereço
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger mb-3 mt-2"
                    @click="
                      alterar = !alterar;
                      mock.telefone = '';
                    "
                    v-if="!alterar"
                  >
                    Não, meu endereço mudou
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row m-0 p-0"
            style="min-height: 100vh; max-height: 100vh"
            v-if="step !== 3"
          >
            <div
              :class="
                'col-md-12 d-flex ' +
                (totem.header ? '' : 'justify-content-center')
              "
              :style="
                'background:' +
                (totem.degrade
                  ? 'radial-gradient(' +
                    totem.cor +
                    ' 0%, ' +
                    totem.cor +
                    ' 30%, #ccc 100%)'
                  : totem.cor) +
                '; flex-direction: column;'
              "
            >
              <div
                class="row justify-content-center"
                style="
                  display: flex !important;
                  align-content: center !important;
                  min-height: 70vh;
                "
                v-if="step === 0 && totem.disposicao !== 2"
                id="step0"
                @click="
                  stepReset();
                  step++;
                "
              >
                <img
                  :src="totem.img_descanso ? totem.img_descanso : ''"
                  style="
                    min-width: 100vw;
                    min-height: 100vh;
                    max-width: 100vw;
                    max-height: 100vh;
                  "
                />
              </div>
              <div
                :class="
                  totem.header ? 'row text-center align-items-center' : 'd-none'
                "
                style="height: 15vh; width: 100vw"
                :style="'background: ' + totem.background_header + ';'"
                v-if="step > 0 || totem.disposicao === 2"
              >
                <div :class="totem.relogio ? 'col-md-3 text-left' : 'd-none'">
                  <h5
                    style="font-weight: 500"
                    :style="
                      'font-size: ' +
                      totem.fonte_relogio +
                      'vh; color: ' +
                      totem.color_header +
                      '!important;'
                    "
                  >
                    <b>Horário:</b> {{ data }} {{ hora }}
                  </h5>
                  <h5
                    style="font-weight: 500"
                    :style="'color: ' + totem.color_header + '!important;'"
                    v-show="document.nome && totem.disposicao === 2"
                  >
                    <b v-if="totem.disposicao !== 2"
                      >{{ configuracao.tipo_pessoa }}:</b
                    ><br />{{ document.nome }}
                  </h5>
                </div>
                <div
                  :class="totem.relogio ? 'col-md-6' : 'offset-md-3 col-md-6'"
                >
                  <h2
                    :style="
                      'font-size: ' +
                      totem.fonte_header +
                      'vh; color: ' +
                      totem.color_header +
                      '!important;'
                    "
                  >
                    {{ totem.mensagem_central }}
                  </h2>
                </div>
                <div
                  :class="
                    totem.header
                      ? 'col-md-3 d-flex justify-content-end'
                      : 'd-none'
                  "
                >
                  <img
                    :src="totem.logo_pronta"
                    v-if="totem.logo"
                    style="max-height: 10vh; max-width: 100%"
                    class="mr-4"
                    @click="clickLogo"
                  />
                  <h1
                    style="
                      border: 1px solid black;
                      padding: 1vh;
                      color: black !important;
                    "
                    v-else
                  >
                    LOGO
                  </h1>
                </div>
              </div>
              <div class="row" v-if="totem.disposicao === 2 && step === 0">
                <div
                  class="col-7"
                  style="
                    display: flex !important;
                    align-content: center !important;
                    min-height: 70vh;
                  "
                  v-if="totem.video_totem"
                >
                  <video
                    class="mt-1"
                    :src="totem.video_totem.video_link"
                    style="background: #000000; width: 100%"
                    autoplay
                    muted
                    loop
                  ></video>
                </div>
                <div
                  :class="
                    totem.video_totem
                      ? 'offset-1 col-4 row justify-content-center'
                      : 'col-12 row justify-content-center'
                  "
                  style="
                    display: flex !important;
                    align-content: center !important;
                    min-height: 70vh;
                  "
                >
                  <div class="col-10 mb-1">
                    <h4 class="text-center">Digite abaixo seu documento</h4>
                    <div class="d-flex justify-content-center input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        :style="totem.video_totem ? '' : 'max-width: 30%;'"
                        v-mask="'999.999.999-99'"
                        v-model="documento"
                        disabled
                      />
                      <!-- v-show="tipoDoc === 'CPF'" -->
                      <!-- <input
                        type="text"
                        class="form-control"
                        v-mask="'99999999999 9999'"
                        v-model="documento"
                        v-show="tipoDoc === 'CNS'"
                        disabled
                      /> -->
                    </div>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-itens-center"
                  >
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(1)"
                    >
                      <b style="font-size: 4.5vh">1</b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(2)"
                    >
                      <b style="font-size: 4.5vh">2</b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(3)"
                    >
                      <b style="font-size: 4.5vh">3</b>
                    </button>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-itens-center"
                  >
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(4)"
                    >
                      <b style="font-size: 4.5vh">4</b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(5)"
                    >
                      <b style="font-size: 4.5vh">5</b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(6)"
                    >
                      <b style="font-size: 4.5vh">6</b>
                    </button>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-itens-center"
                  >
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(7)"
                    >
                      <b style="font-size: 4.5vh">7</b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(8)"
                    >
                      <b style="font-size: 4.5vh">8</b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(9)"
                    >
                      <b style="font-size: 4.5vh">9</b>
                    </button>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-itens-center"
                  >
                    <button
                      class="btn btn-danger mr-1 mb-1 py-2 px-1"
                      @click="deleteDoc()"
                    >
                      <b style="font-size: 4.5vh"
                        ><i class="fas fa-eraser"></i
                      ></b>
                    </button>
                    <button
                      class="btn btn-secondary mr-1 mb-1 p-2"
                      @click="inputDoc(0)"
                    >
                      <b style="font-size: 4.5vh">0</b>
                    </button>
                    <button
                      class="btn btn-success mr-1 mb-1 py-2 px-1"
                      @click="identify()"
                      :disabled="documento.length === 11 ? false : true"
                    >
                      <!-- :disabled="(documento.length === 11 || documento.length === 15) ? false : true" -->
                      <b style="font-size: 4.5vh"
                        ><i class="fas fa-check"></i
                      ></b>
                    </button>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-itens-center"
                  >
                    <button
                      class="btn btn-primary mb-1 mr-1 px-2"
                      @click="getFacial()"
                      data-toggle="modal"
                      data-target="#reconhecimentoFacial2"
                    >
                      <b style="font-size: 2vh">Identificar via biometria</b>
                    </button>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-itens-center"
                  >
                    <button
                      class="btn btn-primary mr-1 px-2"
                      @click="
                        getBotoes();
                        step++;
                      "
                    >
                      <b style="font-size: 2.7vh">Não sei meu CPF</b>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                style="
                  display: flex !important;
                  align-content: center !important;
                  min-height: 70vh;
                "
                v-if="identifing && !search"
                id="identify"
              >
                <div class="text-center col-md-12 my-1">
                  <button
                    :class="'btn btn-' + (identifing ? 'danger' : 'dark')"
                    @click="identifing = !identifing"
                    v-show="totem.disposicao !== 2 && !document.nome"
                  >
                    <b>{{ identifing ? "Cancelar" : "Informar Documento" }}</b>
                  </button>
                </div>
                <div class="col-4 mb-1">
                  <h4>Digite abaixo seu documento</h4>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-mask="'999.999.999-99'"
                      v-model="documento"
                      disabled
                    />
                    <!-- v-show="tipoDoc === 'CPF'" -->
                    <!-- <input
                      type="text"
                      class="form-control"
                      v-mask="'99999999999 9999'"
                      v-model="documento"
                      v-show="tipoDoc === 'CNS'"
                      disabled
                    /> -->
                  </div>
                </div>
                <div
                  class="col-12 d-flex justify-content-center align-itens-center"
                >
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(1)"
                  >
                    <b style="font-size: 4.5vh">1</b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(2)"
                  >
                    <b style="font-size: 4.5vh">2</b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(3)"
                  >
                    <b style="font-size: 4.5vh">3</b>
                  </button>
                </div>
                <div
                  class="col-12 d-flex justify-content-center align-itens-center"
                >
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(4)"
                  >
                    <b style="font-size: 4.5vh">4</b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(5)"
                  >
                    <b style="font-size: 4.5vh">5</b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(6)"
                  >
                    <b style="font-size: 4.5vh">6</b>
                  </button>
                </div>
                <div
                  class="col-12 d-flex justify-content-center align-itens-center"
                >
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(7)"
                  >
                    <b style="font-size: 4.5vh">7</b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(8)"
                  >
                    <b style="font-size: 4.5vh">8</b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(9)"
                  >
                    <b style="font-size: 4.5vh">9</b>
                  </button>
                </div>
                <div
                  class="col-12 d-flex justify-content-center align-itens-center"
                >
                  <button
                    class="btn btn-danger mr-1 mb-1 py-2 px-1"
                    @click="deleteDoc()"
                  >
                    <b style="font-size: 4.5vh"
                      ><i class="fas fa-eraser"></i
                    ></b>
                  </button>
                  <button
                    class="btn btn-secondary mr-1 mb-1 p-2"
                    @click="inputDoc(0)"
                  >
                    <b style="font-size: 4.5vh">0</b>
                  </button>
                  <button
                    class="btn btn-success mr-1 mb-1 py-2 px-1"
                    @click="identify()"
                  >
                    <b style="font-size: 4.5vh"><i class="fas fa-check"></i></b>
                  </button>
                </div>
              </div>
              <div
                class="modal fade"
                id="modalAgenda"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalAgenda"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div
                    class="modal-content"
                    style="width: 65vw; margin-left: -15vw; margin-top: -15vh"
                  >
                    <div
                      class="modal-header"
                      style="width: 65vw"
                      :style="
                        'background: ' +
                        (totem.background_header
                          ? totem.background_header
                          : '#15488a') +
                        ';' +
                        'color: ' +
                        (totem.color_header ? totem.color_header : '#ffffff') +
                        ';'
                      "
                    >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div
                        class="row justify-content-center"
                        style="width: 65vw"
                      >
                        <h3 class="text-center" id="exampleModalLongTitle">
                          <b>Você possui agendamentos próximos!</b>
                        </h3>
                        <h5 class="mb-5">
                          Deseja retirar a senha e ser encaminhado direto ao
                          setor do seu agendamento? clique no agendamento
                          desejado para prosseguir
                        </h5>
                        <div
                          class="col-md-6 d-flex justify-content-center"
                          v-for="data in agenda"
                        >
                          <button
                            :class="
                              'btn btn-' +
                              (data.status == 1
                                ? 'primary'
                                : data.status == 3 || data.status == 4
                                ? 'success'
                                : 'danger')
                            "
                            @click="reconhecer(data)"
                            :disabled="doubleClick"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span
                              ><b>{{ data.nome }}</b></span
                            ><br />
                            <span
                              ><b>{{ data.data_agendado_f }}</b></span
                            ><br /><br />
                            <span>{{ data.descricao }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="reconhecimentoFacial2"
                tabindex="-1"
                role="dialog"
                aria-labelledby="reconhecimentoFacial2Label"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div
                      class="modal-header"
                      :style="
                        'background: ' +
                        (totem.background_header
                          ? totem.background_header
                          : '#15488a') +
                        ';' +
                        'color: ' +
                        (totem.color_header ? totem.color_header : '#ffffff') +
                        ';'
                      "
                    >
                      <h5
                        :style="
                          'color: ' +
                          (totem.color_header
                            ? totem.color_header
                            : '#ffffff') +
                          ';'
                        "
                        class="modal-title"
                        id="reconhecimentoFacial2Label"
                      >
                        Reconhecimento facial
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12">
                          <video ref="camera2" autoplay width="100%"></video>
                          <canvas
                            ref="canvas2"
                            width="640"
                            height="480"
                            style="display: none"
                          ></canvas>
                        </div>
                        <div class="col-12 text-center my-1">
                          <button
                            class="btn btn-outline-primary"
                            @click="identificarFoto()"
                          >
                            Iniciar reconhecimento facial
                          </button>
                          <div
                            class="alert alert-danger mt-2 p-1 text-center"
                            role="alert"
                            v-show="reconhecido"
                          >
                            Não reconhecido
                          </div>
                          <div
                            class="alert alert-secondary mt-2 p-1 text-center"
                            role="alert"
                            v-show="carregando"
                          >
                            Carregando...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="reconhecimentoFacial"
                tabindex="-1"
                role="dialog"
                aria-labelledby="reconhecimentoFacialLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div
                      class="modal-header"
                      :style="
                        'background: ' +
                        (totem.background_header
                          ? totem.background_header
                          : '#15488a') +
                        ';' +
                        'color: ' +
                        (totem.color_header ? totem.color_header : '#ffffff') +
                        ';'
                      "
                    >
                      <h5
                        :style="
                          'color: ' +
                          (totem.color_header
                            ? totem.color_header
                            : '#ffffff') +
                          ';'
                        "
                        class="modal-title"
                        id="reconhecimentoFacialLabel"
                      >
                        Reconhecimento facial
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12">
                          <video ref="camera" autoplay width="100%"></video>
                          <canvas
                            ref="canvas"
                            width="640"
                            height="480"
                            style="display: none"
                          ></canvas>
                        </div>
                        <div class="col-12 text-center my-1">
                          <button
                            class="btn btn-outline-primary"
                            @click="tirarFoto()"
                          >
                            Iniciar reconhecimento facial
                          </button>
                          <div
                            class="alert alert-danger mt-2 p-1 text-center"
                            role="alert"
                            v-show="reconhecido"
                          >
                            Não reconhecido
                          </div>
                          <div
                            class="alert alert-secondary mt-2 p-1 text-center"
                            role="alert"
                            v-show="carregando"
                          >
                            Carregando...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="" v-if="identifing && search">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="min-height: 40vh"
                >
                  <h2>Carregando, por favor aguarde...</h2>
                </div>
              </div>
              <div
                class="row justify-content-center"
                style="
                  display: flex !important;
                  align-content: start !important;
                  min-height: 70vh;
                "
                v-if="step === 1 && !identifing"
                id="step1"
              >
                <div class="text-center col-md-12 my-1">
                  <button
                    :class="'btn btn-' + (identifing ? 'danger' : 'dark')"
                    @click="identifing = !identifing"
                    v-show="totem.disposicao !== 2 && !document.nome"
                  >
                    <b>{{ identifing ? "Cancelar" : "Informar Documento" }}</b>
                  </button>
                </div>
                <button
                  v-for="botao in botoes"
                  :class="
                    'btn d-flex ' +
                    (botao.simbolo_id
                      ? 'justify-content-between'
                      : 'justify-content-center') +
                    ' align-items-center mt-2 mx-2 ' +
                    (totem.disposicao
                      ? botoes.length > 7
                        ? 'col-md-3'
                        : botoes.length > 3
                        ? 'col-md-5'
                        : 'col-md-8'
                      : 'col-md-3')
                  "
                  :style="
                    'font-size: 5vh; border-radius:' +
                    totem.arredondamento +
                    'px; background:' +
                    botao.cor +
                    '; color: ' +
                    botao.cor_texto +
                    ';' +
                    (totem.disposicao != 1 ? 'height: 10vh;' : 'height: 10vh;')
                  "
                  @click="
                    getBotoesFilhos(
                      botao.id,
                      botao.preferencial,
                      botao.prefixo_senha,
                      botao.concatena_prefixo
                    )
                  "
                  :disabled="doubleClick"
                >
                  {{ botao.nome }}
                  <img
                    :class="botao.simbolo_id ? '' : 'd-none'"
                    :src="botao.simbolo.photo"
                    height="100%"
                    v-if="botao.simbolo"
                  />
                </button>
                <div
                  class="btn col-md-10 text-center mt-2 mx-2"
                  :style="
                    'position: absolute; bottom: 5%; font-size: 5vh; border-radius:' +
                    totem.arredondamento +
                    'px; background: #aaaaaa; color: #ffffff;' +
                    (totem.disposicao === 0 ? 'height: 10vh;' : 'height: 10vh;')
                  "
                  @click="cancelar()"
                >
                  Cancelar
                </div>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center align-items-center m-0 p-0"
            v-else
          >
            <div
              class="col-6 d-flex flex-column justify-content-center align-items-center text-justify text-primary"
              style="min-height: 100vh; max-height: 100vh"
            >
              <span style="font-size: 20vh; line-height: 18vh">
                <span class="text-danger text-center">{{
                  senhaGerada.prefixo
                }}</span
                >{{ senhaGerada.senha }}
              </span>
              <span
                class="text-center"
                style="font-size: 10vh; line-height: 15vh"
                >{{ senhaGerada.setor }}</span
              >
              <span style="font-size: 4vh" class="mb-2"
                >Senha Retirada em {{ senhaGerada.data }}</span
              >
              <h4 class="text-primary text-center">
                {{ senhaGerada.mensagem }}
              </h4>
            </div>
          </div>
          <div
            :class="step !== 0 && step !== 3 && totem.tempo > 0 ? '' : 'd-none'"
            style="position: absolute; left: 8px; bottom: 8px"
            :style="
              'color: ' + totem.cor + ';' + totem.degrade
                ? 'filter: invert(0.75);'
                : 'filter: invert(0);'
            "
          >
            Tempo até voltar para o menu principal
            <b>{{ totem.tempo - timer }}</b> segundos
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-column"
          v-else
          style="min-height: 100vh"
        >
          <div class="d-flex justify-content-center align-items-center">
            <h1>Esse totem não existe</h1>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <router-link to="/" class="btn btn-primary mr-1">
              <i class="fas fa-arrow-left"></i> Retornar a homepage
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 100vh"
        v-else
      >
        <h2>Carregando totem, por favor aguarde...</h2>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center p-1"
      :style="
        'width: 100vw; height: 100vh; background:' +
        totem.cor +
        '; flex-direction: column;'
      "
      v-else
    >
      <h1 :style="'color:' + totem.cor_texto">TOTEM INATIVO</h1>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      carregando: false,
      reconhecido: false,
      agendaAtual: [],
      fotoUrl: null,
      clickCount: 0,
      clickTimeout: null,
      loaded: false,
      doubleClick: false,
      identifing: false,
      search: false,
      timer: 0,
      primeiroLoad: 0,
      tipoDoc: "CPF",
      reset: "",
      prefixo: "",
      totem: {
        ativo: 1,
      },
      botoes: {},
      preferencial: 0,
      preferencial80: 0,
      data: "",
      hora: "",
      step: 0,
      botao_id: 0,
      configuracao: [],
      documento: "",
      document: [],
      senhaGerada: {
        prefixo: "",
        senha: "",
        setor: "",
        data: "",
        mensagem: "",
      },
      agenda: [],
      alterar: false,
      mock: {
        cep: "08330-140",
        tipo: "RESIDENCIAL",
        logradouro: "RUA FRANCISCO LUSTOSA",
        numero: "93",
        complemento: "",
        bairro: "JARDIM VERA CRUZ",
        municipio: "SÃO PAULO",
        uf: "SP",
        telefone: "11987654321",
      },
      setor_id: 0,
      prioridade_atual: 1,
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getTotem: function (id) {
      const self = this;
      const api = self.$store.state.api + `totens/${id}?with[]=video_totem`;

      axios
        .get(api)
        .then((response) => {
          self.totem = response.data.data[0];

          if (!(self.totem.tempo >= 0 && self.primeiroLoad === 0)) {
            self.step = 1;
            self.primeiroLoad++;
          }

          if (response.data.data.length) {
            self.totem.length = 1;
          }

          self.loaded = true;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    searchTotem: function () {
      const self = this;
      const api =
        self.$store.state.api + "totens?ativo=1&orderByDesc=created_at";

      axios
        .get(api)
        .then((response) => {
          self.getTotem(response.data.data[0].id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBotoes: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "botoes?with[]=simbolo&ativo=1&orderByAsc=ordem&paginated=false";

      self.botoes = [];

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let i = 0;

          response.data.data.forEach((botao) => {
            if (!botao.botao_pai) {
              self.botoes[i] = botao;
              i++;
            }
          });

          self.$loading(false);
          self.doubleClick = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBotoesFilhos: function (id, preferencial, prefixo, concatena) {
      const self = this;
      const api =
        self.$store.state.api +
        `botoes_totens?paginated=false&with[]=botao_filho&botao_pai_id=${id}`;

      if (concatena) {
        self.prefixo += prefixo;
      }

      if (preferencial > self.prioridade_atual) {
        self.prioridade_atual = preferencial;
      }

      self.botoes = [];

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          if (response.data.data.length) {
            let i = 0;
            response.data.data.forEach((botao) => {
              if (botao.botao_filho && botao.botao_filho.ativo) {
                self.botoes[i] = botao.botao_filho;
                i++;
              }
            });

            if (self.botoes.length) {
              self.doubleClick = false;
              self.$loading(false);
              return;
            }
          }
          self.$loading(false);

          self.abreSenha(id, self.prioridade_atual);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    abreSenha: function (id, prioridade) {
      const self = this;
      const api = self.$store.state.api + "cria_senha";

      self.$loading(false);
      self.doubleClick = true;

      self.stepReset();

      axios
        .post(api, {
          botao_id: id,
          cpf: self.document.cpf,
          prioridade: prioridade,
          documento: self.document,
          impressora: self.totem.impressora,
          setor_id: self.setor_id,
          prefixo: self.prefixo,
        })
        .then((response) => {
          self.senhaGerada = response.data;
          self.step = 3;
          self.getBotoes();

          setTimeout(function () {
            self.step =
              self.totem.disposicao === 2 ? 0 : self.totem.tempo ? 0 : 1;
            self.alterar = false;
          }, self.totem.timeout);

          self.documento = "";
          self.document = [];
          self.prioridade_atual = 1;
          self.prefixo = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    cancelar: function () {
      const self = this;

      self.$loading(true);

      self.step = self.totem.disposicao === 2 ? 0 : self.totem.tempo ? 0 : 1;
      self.identifing = false;
      self.document = [];
      self.documento = "";
      self.alterar = false;
      self.prioridade_atual = 1;
      self.prefixo = "";

      self.getBotoes();

      self.$loading(false);
    },
    getHorario: function () {
      const self = this;

      setInterval(() => {
        const date = new Date();
        let ano = date.getFullYear();
        let mes = date.getMonth() + 1;
        let dia = date.getUTCDate();
        let horas = date.getHours();
        let minutos = date.getMinutes();
        let segundos = date.getSeconds();

        self.hora =
          horas.toString().padStart(2, "0") +
          ":" +
          minutos.toString().padStart(2, "0") +
          ":" +
          segundos.toString().padStart(2, "0");
        self.data =
          dia.toString().padStart(2, "0") +
          "/" +
          mes.toString().padStart(2, "0") +
          "/" +
          ano;
      }, 1000);
    },
    getDocumento: function (documento) {
      // Sempre que é digitado algo no totem, aqui é chamado
      const self = this;

      if (documento != "Enter") {
        self.documento += documento;
        self.tipoDoc = "cpf";
      } else {
        let api = self.$store.state.api + "get-cpf";
        self.$loading(true);

        if (self.documento.length > 11) {
          self.tipoDoc = "CNS";
        }

        axios
          .post(api, { documento: self.documento, tipoDocumento: self.tipoDoc })
          .then((response) => {
            var data = response.data;

            if (data.erro == "CPF não encontrado") {
              self.documento = "";
              self.$message("Atenção!", "CPF não encontrado", "warning");
              return;
            }

            self.document = response.data;

            self.stepReset();

            if (response.data.erro === "CPF não encontrado") {
              self.documento = "";
              self.$message("Atenção!", "CPF não encontrado", "warning");
              self.$loading(false);
              return;
            }

            self.document = response.data;

            self.identifing = false;

            if (self.totem.disposicao === 2) {
              $("#infoPessoa").modal("toggle");

              self.step++;
            }
            self.$loading(false);
          })
          .catch((error) => {
            self.$message("Atenção!", "CPF não encontrado", "warning");
            self.documento = "";
            self.$loading(false);
          });
      }
    },
    identify: function () {
      const self = this;

      if (self.documento.length === 11 || self.documento.length === 15) {
        self.search = true;

        self.stepReset();
        self.getPessoa();
      } else {
        self.$message(
          "Atenção!",
          "Por favor, digite um número válido",
          "error"
        );
      }
    },
    trocaDoc: function () {
      const self = this;

      if (self.tipoDoc === "CPF") {
        self.tipoDoc = "CNS";
      } else {
        self.tipoDoc = "CPF";
      }
    },
    getPessoa: function () {
      const self = this;
      const api = self.$store.state.api + "get-cpf";

      self.$loading(true);
      self.stepReset();

      axios
        .post(api, { documento: self.documento, tipoDocumento: self.tipoDoc })
        .then((response) => {
          self.search = false;
          self.stepReset();

          if (response.data.erro === "CPF não encontrado") {
            self.$message("Atenção!", "CPF não encontrado", "warning");
            self.documento = "";
            self.$loading(false);
            return;
          }
          self.document = response.data;

          self.identifing = false;

          if (self.totem.disposicao === 2) {
            $("#infoPessoa").modal("toggle");

            self.step++;
          }

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.search = false;

          self.$message("Atenção!", "CPF não encontrado", "warning");
          self.documento = "";
        });
    },
    getAgenda: function () {
      const self = this;
      const api = self.$store.state.api + "agenda";
      self.stepReset();

      axios
        .post(api, { cpf: self.documento })
        .then((response) => {
          self.stepReset();
          if (response.data.length) {
            self.agenda = response.data;
            $("#modalAgenda").modal("toggle");
          }
          self.$loading(false);
        })
        .catch(() => {
          self.$loading(false);
          self.$message("Atenção!", "Nenhuma consulta agendada", "info");
        });
    },
    async iniciarCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.$refs.camera.srcObject = stream;
        this.$refs.camera2.srcObject = stream;
      } catch (error) {
        console.error("Erro ao acessar a câmera:", error);
      }
    },
    tirarFoto() {
      const self = this;
      const video = this.$refs.camera;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      self.reconhecido = false;
      self.carregando = true;
      self.$loading(true);

      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      self.fotoUrl = canvas.toDataURL("image/png");

      const api = self.$store.state.api + "reconhecimento-facial";

      axios
        .post(api, { pessoa: self.document, foto: self.fotoUrl })
        .then((response) => {
          if (response.data.reconhecido) {
            $("#reconhecimentoFacial").modal("hide");
            self.pessoaPresente(this.agendaAtual);
          } else {
            self.carregando = false;
            self.reconhecido = true;

            setTimeout(() => {
              self.reconhecido = false;
            }, 3000);
          }

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    reconhecer: function (data) {
      const self = this;

      $("#reconhecimentoFacial").modal("toggle");

      self.iniciarCamera();
      self.agendaAtual = data;
    },
    getFacial: function () {
      const self = this;

      self.reconhecido = false;
      self.carregando = false;

      self.iniciarCamera();
    },
    identificarFoto: function () {
      const self = this;
      const video = this.$refs.camera2;
      const canvas = this.$refs.canvas2;
      const context = canvas.getContext("2d");

      self.reconhecido = false;
      self.carregando = true;
      self.$loading(true);

      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      self.fotoUrl = canvas.toDataURL("image/png");

      const api = self.$store.state.api + "reconhecimento-facial";

      axios
        .post(api, { foto: self.fotoUrl })
        .then((response) => {
          if (response.data.reconhecido) {
            $("#reconhecimentoFacial2").modal("hide");

            self.documento = response.data.texto;
            self.tipoDoc = "cpf";

            self.getPessoa();
          } else {
            self.carregando = false;
            self.reconhecido = true;

            setTimeout(() => {
              self.reconhecido = false;
            }, 3000);
          }

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    pessoaPresente: function (data) {
      const self = this;
      const api = self.$store.state.api + "agendamentos/" + data.id;

      self.setor_id = data.setor_id;

      data._method = "PUT";
      data.status = 3;
      self.stepReset();

      axios
        .post(api, data)
        .then((response) => {
          self.abreSenha(0, 1);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    sendMessage: function () {
      const self = this;
      const api =
        self.$store.state.api + "update-register/55" + self.mock.telefone;

      self.stepReset();

      axios
        .get(api)
        .then((response) => {
          self.stepReset();

          self.alterar = false;
          self.mock.telefone = "";

          self.$message("Atenção!", "Mensagem enviada!", "info");
          self.getAgenda();
        })
        .catch(() => {
          self.$message("Atenção!", "Erro ao enviar a mensagem", "info");
        });
    },
    inputDoc: function (number) {
      if (this.documento.length < 11) {
        this.documento += number;
      }

      // if (this.documento.length > 11) {
      //   this.tipoDoc = "CNS";
      // } else {
      //   this.tipoDoc = "CPF";
      // }
    },
    deleteDoc: function () {
      this.documento = this.documento.slice(0, -1);

      if (this.documento.length > 11) {
        this.tipoDoc = "CNS";
      } else {
        this.tipoDoc = "CPF";
      }
    },
    stepReset: function () {
      clearTimeout(this.reset);
      this.timer = 0;
      if (this.totem.tempo) {
        this.reset = setTimeout(() => {
          this.timer = 0;
          this.getBotoes();
          this.cancelar();
          $("#modalAgenda").modal("hide");
          $("#infoPessoa").modal("hide");
        }, this.totem.tempo * 1000);
      }
    },
    resetClickCount() {
      clearTimeout(this.clickTimeout);
      this.clickCount = 0;
    },
    cancelHold() {
      clearTimeout(this.holdTimeout);
    },
    reload() {
      location.reload();
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    clickLogo: function () {
      const self = this;
      self.clickCount++;

      if (self.clickCount === 1) {
        self.clickTimeout = setTimeout(() => {
          self.resetClickCount();
        }, 1000);
      }

      if (self.clickCount >= 5) {
        clearTimeout(self.clickTimeout);
        self.reload();
        self.resetClickCount();
      }
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getConfiguracao();
    self.getHorario();
    self.getBotoes();

    if (id) {
      self.getTotem(id);
    } else {
      self.searchTotem();
    }

    $(document).keydown(function () {
      self.getDocumento(event.key);
      self.stepReset();
    });

    setInterval(() => {
      if (self.step !== 0) {
        self.timer++;
      }
    }, 1000);

    setInterval(() => {
      self.getTotem(id);
    }, 60000);

    $(document).click(function () {
      self.stepReset();
    });
  },
  created() {},
};
</script>

<style>
html,
body {
  font-family: helvetica;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1.3s ease-in-out;
  -moz-animation: fadeIn 1.3s ease-in-out;
  -o-animation: fadeIn 1.3s ease-in-out;
  animation: fadeIn 1.3s ease-in-out;
}

body {
  overflow-x: hidden;
}

div > #mensagens {
  display: relative;
  width: 200%;
  grid-template-columns: repeat(2, 0.25fr);
  justify-content: space-between;
  transform: translateX(100z);
  animation: anima 90s linear infinite;
}

#mensagens:before,
#mensagens:after {
  content: "";
  display: relative;
}

#mensagens:after {
  transform: translate3d(100%, 0, 0);
}

@keyframes anima {
  from {
    transform: translateX(110%);
  }

  to {
    transform: translateX(-160%);
  }
}
</style>
